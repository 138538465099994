import api from '@/plugins/api.js'
import { preset as endpoints } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'
import { PAGE_SIZE_PRESET } from '@/configs/constants'

const store = 'preset'

export default {
  state: () => ({
    presets: []
    // macros: [],
    // // isViewMacrosDialog: false,
    // isEditMacros: false,
    // selectedMacros: {}
  }),
  actions: {
    async getActionsGlobal ({ dispatch, commit }) {
      const { results } = await dispatch('getPresets', { page_size: 100, custom_preset: true })
      if (results) {
        commit('SET_DATA', { store, state: 'presets', data: results })
      }
    },
    async getPresetsGlobal ({ dispatch, commit }) {
      const { results } = await dispatch('getPresets', { page_size: 100, custom_preset: false })
      if (results) {
        commit('SET_DATA', { store, state: 'presets', data: results })
      }
    },
    async getStopWords (context, id = null) {
      const response = await api.get(endpoints.stopWords(id))
      if (await requestValidation(response)) {
        return response.data
      }
    },
    async getPreset (context, id) {
      const response = await api.get(endpoints.presetAction(id))
      if (await requestValidation(response)) {
        return response.data
      }
    },
    async getPresets (context, params = {}) {
      params.page_size = params.page_size || PAGE_SIZE_PRESET
      let getParams = params && Object.keys(params)
        .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)
      const response = await api.get(`${ endpoints.preset }${ getParams ? `?${ getParams }` : '' } `)

      if (await requestValidation(response)) {
        return response.data
      }
    },
    async getMacros () {
      const response = await api.get(endpoints.macros)
      if (await requestValidation(response)) {
        response.data.sort((a, b) => {
          return a.id - b.id
        })
        return response.data
      }
    },

    async postPreset (context, body) {
      const response = await api.post(endpoints.preset, { body })
      const isResult = await requestValidation(response)
      if (isResult) {
        context.commit('ADD_DATA', { store, state: 'presets', data: response.data })
      }
      return { status: isResult, id: response.data.id }
    },

    async patchPreset (context, body) {
      const response = await api.patch(endpoints.presetAction(body.id), { body })
      const isResult = await requestValidation(response)
      if (isResult) {
        context.commit('UPDATE_DATA', { store, state: 'presets', data: response.data })
      }
      return { status: isResult, id: response.data.id }
    },

    async deletePreset (context, id) {
      const response = await api.delete(endpoints.presetAction(id))
      const isResult = await requestValidation(response)
      isResult && await context.dispatch('getPresets')
      return isResult
    }

    // async postMacros (context, body) {
    //   const response = await api.post(endpoints.macros, { body })
    //   const isResult = await requestValidation(response)
    //   if (isResult) {
    //     context.commit('ADD_DATA', { store, state: 'macros', data: response.data })
    //   }
    //   return { status: isResult, id: response.data.id }
    // },

    // async patchMacros (context, body) {
    //   const response = await api.patch(endpoints.macrosAction(body.id), { body })
    //   const isResult = await requestValidation(response)
    //   if (isResult) {
    //     context.commit('UPDATE_DATA', { store, state: 'macros', data: response.data })
    //     context.commit('SET_DATA', { store, state: 'selectedMacros', data: response.data })
    //   }
    //   return isResult
    // }

    // async deleteMacros (context, id) {
    //   const response = await api.delete(endpoints.macrosAction(id))
    //   return await requestValidation(response)
    // }
  },
  getters: {
    presetById: state => id => state.presets.find(item => Number(item.id) === Number(id)),
    // macrosById: state => id => state.macros.find(item => Number(item.id) === Number(id)),
    presetByOnuType: state => id => state.presets.filter(item => item.onu_types.includes(Number(id)))
  }
}
