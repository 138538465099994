export default [
  {
    path: '/periodic-task',
    name: 'periodic-task',
    meta: {
      access: ['view_periodictask', 'add_periodictask', 'change_periodictask', 'delete_periodictask']
    },
    component: () => import('@/views/PeriodicTask/PeriodicTask')
  },
  {
    path: '/periodic-task/:id',
    name: 'periodic-task-page',
    meta: {
      access: ['view_periodictask', 'add_periodictask', 'change_periodictask', 'delete_periodictask'],
      prevRoutes: ['periodic-task']
    },
    redirect: '/periodic-task/:id/info'
  },
  {
    path: '/periodic-task/:id/info',
    name: 'periodic-task-info',
    meta: {
      access: ['view_periodictask', 'add_periodictask', 'change_periodictask', 'delete_periodictask'],
      prevRoutes: ['periodic-task']
    },
    component: () => import('@/views/PeriodicTask/Info')
  },
  {
    path: '/periodic-task/:id/edit',
    name: 'periodic-task-edit',
    meta: {
      access: 'change_periodictask'
    },
    component: () => import('@/views/PeriodicTask/Form')
  }
]
