<template lang="pug">
  div
    div(v-if="isLoadingApp")
      Loader
    div(v-else)
      v-app(v-if="isGuestRoute").ep
        div(:class="isLoginRoute && 'login-background'").d-flex.flex-grow-1.justify-center.public-route.px-4
          router-view
      v-app(v-else).ep
        Navigation
        div(:class="isMini ? 'v-main-mini' : 'v-main'")
          v-expand-transition(duration="100")
            v-card(v-if="isShowBillNotification" elevation="0").vContainer
              div.global-bill-notification.d-flex.flex-row.align-start
                img(:src="slash").mr-3
                .d-flex.flex-column.w-full
                  div.d-flex.flex-row.justify-space-between.mb-1
                    span.font14.global-bill-notification-title {{ $t('globalBillNotificationTitle') }}
                    img(:src="closeRed" @click="toggleBillNotification({ isShowNotify: false })").cursor-pointer
                  span.font14.mb-3 {{ $t('globalBillNotificationText') }}
                  a(:href="cabinet" target="_blank").font14.global-bill-notification-link.d-flex.flex-row.align-center
                    | {{ $t('loginToPayTheBil') }}
                    img(:src="rowRightRed").ml-2
          router-view
</template>

<script>
import Navigation from '@/components/Navigation'
import Loader from '@/views/Loader'
import { mapActions, mapMutations, mapState } from 'vuex'
import closeRed from '@/assets/icons/close-red.svg'
import slash from '@/assets/icons/slash.svg'
import rowRightRed from '@/assets/icons/row-right-red.svg'
import warningImage from '@/assets/icons/modal-warning.svg'

export default {
  name: 'App',
  components: {
    Navigation,
    Loader
  },
  data () {
    return {
      cabinet: process.env.VUE_APP_CABINET,
      breakpoint: 960,
      isGuestRoute: true,
      isLoginRoute: false,
      closeRed,
      rowRightRed,
      slash,
      warningImage
    }
  },
  computed: {
    ...mapState({
      isShowBillPopup: state => state.license.isShowBillPopup,
      isNeedToPay: state => state.license.isNeedToPay,
      isShowBillNotification: state => state.license.isShowBillNotification,
      isLoadingApp: state => state.app.isLoad,
      isMini: state => state.app.isMini
    }),
    isAuth () {
      return this.$auth.isAuth()
    }
  },
  watch: {
    isShowBillPopup (isShow) {
      if (isShow)
        this.$swal({
          title: this.$t('globalBillNotificationTitle'),
          text: this.$t('globalBillNotificationText'),
          icon: this.warningImage,
          buttons: [this.$t('btn.cancel'), { text: this.$t('btn.pay'), closeModal: true }]
        }).then(async isPay => {
          this.toggleBillNotification({ isShowPopup: false })

          if (isPay)
            window.open(this.cabinet, '_blank')
          this.getLicense()
          this.$swal.close()
        })
    },
    '$route' (route, from) {
      if (this.isNeedToPay) {
        this.toggleBillNotification({ isShowNotify: true, isShowNav: true })
      }
      if (from.meta.public || from.name === null) {
        this.SET_DATA({ store: 'app', state: 'isLoad', data: false })
      }
      this.isGuestRoute = route.meta.public || false
      this.isLoginRoute = route.name === 'login' || false
    }
  },
  async mounted () {
    window.addEventListener('resize', this.handleResize)
    this.SET_DATA({ store: 'app', state: 'isMobile', data: window.innerWidth < this.breakpoint })
    if (this.isAuth) {
      await this.loadDataOnLogin()
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapMutations(['SET_DATA']),
    ...mapActions(['loadDataOnLogin', 'toggleBillNotification', 'getLicense']),
    handleResize () {
      this.SET_DATA({ store: 'app', state: 'isMobile', data: window.innerWidth < this.breakpoint })
    }
  }
}
</script>
