import store from '@/store'

const profile = store.state.profile

export const access = typeAccess => {
  if (typeAccess === 'public') return true

  if (typeof typeAccess === 'object') {
    return typeAccess.some(p => profile.permissions.includes(p))
  }
  else return profile.permissions.includes(typeAccess)

}
