export default [
  {
    path: '/building/:id',
    name: 'building-info',
    meta: {
      access: ['view_building', 'add_building', 'change_building', 'delete_building'],
      prevRoutes: ['building', 'building-info']
    },
    component: () => import('@/views/Building/Info')
  },
  {
    path: '/building',
    name: 'building',
    meta: {
      access: ['view_building'],
      prevRoutes: ['building']
    },
    component: () => import('@/views/Building/Building')
  }
]
