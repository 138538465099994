import { PAGE_SIZE_ONU_DUPLICATES } from '@/configs/constants'
import api from '@/plugins/api'
import {onuDuplicates as endpoint} from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'

const store = 'onuDuplicate'

export default {
  state: () => ({
    onuDuplicates: [],
    isLoadingAllOnuDuplicates: true,
    infoOnuDuplicate: null,
    isLoad: false
  }),
  actions: {
    async getAllOnuDuplicates(context, params) {
      context.commit('SET_DATA', { store, state: 'isLoadingAllOnuDuplicates', data: true })

      params.page_size = PAGE_SIZE_ONU_DUPLICATES
      let getParams = params && Object.keys(params)
          .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)

      const response = await api.get(`${ endpoint.onuDuplicates }${ getParams ? `?${ getParams }` : ''} `)

      if (await requestValidation(response, params.page === 1)) {
        context.commit('SET_DATA', { store, state: 'onuDuplicates', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoadingAllOnuDuplicates', data: false })

      return response.code
    },

    async deleteOnuDuplicate (context, id) {
      const response = await api.delete(endpoint.delete(id))
      return await requestValidation(response)
    }
  }
}
