export default {
  install (Vue) {
    let isLock = false

    function toggle () {
      isLock = !isLock
      document.documentElement.classList.toggle('lock')
    }

    function disable () {
      if (isLock) return
      isLock = true
      document.documentElement.classList.add('lock')
    }

    function enable () {
      if (!isLock) return
      isLock = false
      document.documentElement.classList.remove('lock')
    }

    Vue.prototype.$scrollLooker = {
      toggle, disable, enable
    }
  }
}
