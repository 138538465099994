<template lang="pug">
  include ../plugins/pug/template

  v-navigation-drawer(
    v-model="drawer"
    :mini-variant="isMini"
    mini-variant-width="88"
    :width="248"
    :class="isMini && 'ismini'"
    permanent
    touchless
    app).drawer
    div(:class="isMini && 'px-6 py-12'").pa-12.drawer__head
      v-img(v-if="isMini" :src="LogoMini" width="40" max-height="40" class="ismini__img")
      v-img(v-else :src="Logo" width="160" max-height="32")
    div
      v-list.pa-0.drawer__none
        router-link(
          v-for="(item, key) in menu"
          :to="item.link"
          :key="item.index"
          @click.native="onMenuClick(key)"
        )
          v-list-item(
            :class="{ 'drawer-items--active': isActiveLink(item.routeName) || item.activeRoutes?.some(route => isActiveLink(route)) }"
          ).drawer-items
            v-tooltip(right open-on-hover :disabled="!isMini")
              template(#activator="{ on, attrs }")
                v-list-item-icon(v-bind="attrs" v-on="on").ml-0
                  v-img(
                    :src="require(`@/assets/icons/${ getIcon(item) }`)",
                    :class="{ 'drawer-icons-active': isActiveLink(item.routeName) }"
                  ).drawer-icons
              span {{ item.title }}
            v-list-item-content(v-if="!isMini")
              v-list-item-title.white--text.font14 {{ item.title }}

          v-expand-transition(duration="50")
            v-list(
              v-if="showChildren === item.title"
              transition="slide-x-transition"
            )
              router-link(
                v-for="child in item.children"
                :to="child.link"
                :key="child.index"
              )
                v-list-item(
                  v-if="!child.isHidden"
                  :class=`{
                    'drawer-items--active drawer-items-child': isActiveLink(child.routeName),
                    'pl-14': !isMini,
                    'pl-8': isMini
                  }`
                ).drawer-items
                  v-list-item-icon
                    v-tooltip(right open-on-hover :disabled="!isMini")
                      template(#activator="{ on, attrs }")
                        v-img(
                          :src="require(`@/assets/icons/${ getIcon(child) }`)"
                          v-bind="attrs"
                          v-on="on"
                        ).drawer-icons
                      span {{ child.title }}
                  v-list-item-content(v-if="!isMini")
                    v-list-item-title(
                      :class="isActiveLink(child.routeName) ? 'primary--text' : 'white--text'"
                    ).font14 {{ child.title }}
      v-expand-transition(duration="100")
        div(v-if="isShowBillNav").d-flex.flex-row.justify-center.align-center.pa-3
          div(:class="isMini ? 'nav-bill-notification_mini justify-center align-center' : ''").nav-bill-notification.d-flex.flex-column.w-full.pa-3
            div( :class="!isMini ? 'justify-space-between mb-2' : 'justify-center align-center'").d-flex.flex-row
              v-tooltip(right open-on-hover :disabled="!isMini")
                template(#activator="{ on, attrs }")
                  img(
                    v-bind="attrs"
                    v-on="on"
                    :src="slash"
                    alt="slash"
                  ).cursor-pointer
                div.font14
                  | {{ $t('globalBillNotificationTitle') }}
              img(
                v-if="!isMini"
                :src="closeRed"
                alt="close"
                @click="toggleBillNotification({ isShowNav: false })"
              ).cursor-pointer

            div.nav-bill-notification_content
              div.font14.nav-bill-notification-title.mb-1 {{ $t('globalBillNotificationTitle') }}
              div.font14.mb-3 {{ $t('globalBillNotificationText') }}
              a(:href="cabinet" target="_blank").font14.nav-bill-notification-link.d-flex.flex-row.align-center
                | {{ $t('loginToPayTheBil') }}
                img(:src="rowRightRed" alt="arrow").ml-2
      div.drawer-btn(:class="!isMini && 'active'")
        v-btn(
          @click="isMini = !isMini"
          text
          :ripple="false"
          width="36"
          min-width="36"
          height="40"
        )
          img(:src="Drawer")
          v-icon(small).drawer-btn-icon {{ isMini ? 'mdi-chevron-right' : 'mdi-chevron-left' }}
          div.drawer__btn-desc
          div.header__burger(:class="!isMini && 'header__burger--active'")
            span

    div.text-center.margin__auto.drawer__none.drawer__btn-block
      Finder(
        v-if="access('view_onu')"
        :mini="isMini"
        @click.native="hideMenu(true)").mb-3
      UnregisteredOnu(
        v-if="access('add_onu')"
        :mini="isMini"
        @click.native="hideMenu(true)").mb-3
      v-btn(to="/profile" @click.native="hideMenu(true)" :icon="!!isMini").main-button.user-name
        AvatarWrapper(
          v-if="access('view_accountavatar')"
          :name="user?.username"
          :avatar="user?.avatars[0]"
          :size="!isMini ? 'small' : 'medium'"
          :class="{'mr-3': !isMini}"
        )
        span(v-if="!isMini").text-ellipsis {{ userName }}
      div(v-if="!isMini").sub-header.text-center {{ versionEPV2 }}
      v-spacer(v-else).mb-16
</template>

<script>
import Logo from '@/assets/logo.svg'
import LogoMini from '@/assets/logo-mini.svg'
import Drawer from '@/assets/icons/drawer.svg'
import { access } from '@/configs/permissions'
import { mapActions, mapMutations, mapState } from 'vuex'
import { version } from '@/configs/version'
import closeRed from '@/assets/icons/close-red.svg'
import slash from '@/assets/icons/slash.svg'
import rowRightRed from '@/assets/icons/arrow-right-extra.svg'

export default {
  components: {
    AvatarWrapper: () => import('@/components/Avatar'),
    UnregisteredOnu: () => import('@/views/Onu/Unregistered'),
    Finder: () => import('@/views/Onu/Finder')
  },
  data () {
    return {
      cabinet: process.env.VUE_APP_CABINET,
      access,
      Logo,
      Drawer,
      LogoMini,
      closeRed,
      rowRightRed,
      slash,
      drawer: true,
      showChildren: ''
    }
  },
  computed: {
    versionEPV2 () {
      const result = []
      this.versionFront && result.push(this.versionFront)
      this.versionBack && result.push(this.versionBack)
      return result.join(' / ')
    },
    userName () {
      return `${ this.user?.last_name } ${ this.user?.first_name }`
    },
    menu () {
      const menu = []

      !this.isMobile &&
      menu.push({
        title: this.$t('dashboard'),
        icon: 'dashboard-white.svg',
        iconActive: 'dashboard-primary.svg',
        link: '/dashboard',
        routeName: 'dashboard'
      })

      access(['view_onu', 'add_onu', 'change_onu', 'delete_onu']) &&
      menu.push({
        title: this.$t('onu'),
        icon: 'plate-white.svg',
        iconActive: 'plate-primary.svg',
        link: '/onu',
        routeName: 'onu'
      })

      access(['view_olt', 'add_olt', 'change_olt', 'delete_olt']) &&
      menu.push({
        title: this.$t('olt'),
        icon: 'cube-white.svg',
        iconActive: 'cube-primary.svg',
        link: '/olt',
        routeName: 'olt'
      })

      if (!this.isMobile) {

        access(['view_building', 'add_building', 'change_building', 'delete_building']) &&
        menu.push({
          title: this.$t('building'),
          icon: 'building-white.svg',
          iconActive: 'building-primary.svg',
          link: '/building',
          routeName: 'building'
        })

        access(['view_switch', 'add_switch', 'change_switch', 'delete_switch']) &&
        menu.push({
          title: this.$t('switchDevice'),
          icon: 'server-white.svg',
          iconActive: 'server-primary.svg',
          link: '/switch',
          routeName: 'switch'
        })

        access(['view_accountlog']) &&
        menu.push({
          title: this.$t('onuListLog'),
          icon: 'event-white.svg',
          iconActive: 'event-primary.svg',
          link: '/log',
          routeName: 'log'
        })

        const settingsChildren = []

        const childrenIconConfig = {
          icon: 'arrow-right-white.svg',
          iconActive: 'arrow-right-primary.svg'
        }
        if (access(['view_group', 'add_group', 'change_group', 'delete_group']) ||
          access(['view_user', 'add_user', 'change_user', 'delete_user'])
        )
          settingsChildren.push({
            title: this.$t('permissions'),
            link: '/permissions',
            routeName: 'permissions',
            ...childrenIconConfig
          })
        access(['view_periodictask', 'add_periodictask', 'change_periodictask', 'delete_periodictask']) &&
        settingsChildren.push({
          title: this.$t('periodicTask'),
          link: '/periodic-task',
          routeName: 'periodic-task',
          ...childrenIconConfig
        })

        access([
          'view_configonutemplate',
          'add_configonutemplate',
          'change_configonutemplate',
          'delete_configonutemplate'
        ]) &&
        settingsChildren.push({
          title: this.$t('presets'),
          link: '/presets',
          routeName: 'presets',
          ...childrenIconConfig
        }) &&
        settingsChildren.push({
          title: this.$t('action'),
          link: '/actions',
          routeName: 'actions',
          ...childrenIconConfig
        })

        access(['view_config']) && settingsChildren.push({
          title: this.$t('integration'),
          link: '/integration',
          routeName: 'integration',
          ...childrenIconConfig
        })

        if (settingsChildren.length) {
          const firstLink = settingsChildren[0].link
          menu.push({
            title: this.$t('settings'),
            icon: 'cog-white.svg',
            iconActive: 'cog-primary.svg',
            link: firstLink,
            routeName: 'settings',
            activeRoutes: ['periodic-task', 'preset', 'action', 'permissions'],
            children: settingsChildren
          })
        }
      } else
        menu.push({
          title: this.$t('profile'),
          icon: 'account-white.svg',
          iconActive: 'account-primary.svg',
          link: '/profile',
          routeName: 'profile'
        })
      return menu
    },
    ...mapState({
      isShowBillNav: state => state.license.isShowBillNav,
      versionBack: state => state.app.version,
      isMiniApp: state => state.app.isMini,
      user: state => state.profile.user,
      isMobile: state => state.app.isMobile
    }),
    versionFront () {
      return version || 'DEV MODE'
    },
    isMini: {
      get () {
        return this.isMiniApp
      },
      set (value) {
        this.SET_DATA({ store: 'app', state: 'isMini', data: value })
      }
    }
  },
  created () {
    this.onResize()

    const isClosedNotification = 'isOutdatedRelease' in localStorage
    const isViewNotification = access('view_epv2license')

    if (!isClosedNotification && isViewNotification) this.getLatestRelease()
  },
  methods: {
    ...mapMutations(['SET_DATA']),
    ...mapActions(['getLatestRelease', 'toggleBillNotification']),
    hideMenu (isHideChildren = false) {
      this.isMini = this.isMobile || this.isMini
      if (isHideChildren) this.showChildren = ''
    },
    onMenuClick (key) {
      const item = this.menu[key]
      this.hideMenu()
      this.showChildren = item?.children?.length ? item.title : ''
    },
    getIcon ({ routeName, iconActive, icon }) {
      return this.isActiveLink(routeName) ? iconActive : icon
    },
    isActiveLink (link) {
      return this.$route?.name.includes(link)
    },
    onResize () {
      this.isMini = window.innerWidth <= 1260
    }
  }
}
</script>

<style lang="sass" scoped>
@import 'src/assets/sass/constants'

.user-name
  background: $gray-900 !important
  span
    color: $white
    max-width: 140px
</style>
