export default [
  {
    path: '/permissions',
    name: 'permissions',
    meta: {
      // route guard for this route in page because need permissions group or user
      access: 'public'
    },
    component: () => import('@/views/Permissions/Permissions')
  },
  {
    path: '/permissions/group',
    name: 'group',
    meta: {
      access: ['view_group', 'add_group', 'change_group', 'delete_group']
    },
    component: () => import('@/views/Permissions/Group/Group')
  },
  {
    path: '/permissions/group/create',
    name: 'create-group',
    meta: {
      access: 'add_group'
    },
    component: () => import('@/views/Permissions/Group/Form')
  },
  {
    path: '/permissions/group/:id',
    name: 'group-page',
    meta: {
      access: ['view_group', 'add_group', 'change_group', 'delete_group'],
      prevRoutes: ['group']
    },
    redirect: '/group/:id/info'
  },
  {
    path: '/permissions/group/:id/info',
    name: 'group-info',
    meta: {
      access: ['view_group', 'add_group', 'change_group', 'delete_group'],
      prevRoutes: ['group']
    },
    component: () => import('@/views/Permissions/Group/Info')
  },
  {
    path: '/permissions/group/:id/edit',
    name: 'group-edit',
    meta: {
      access: 'change_group'
    },
    component: () => import('@/views/Permissions/Group/Form')
  }
]
