import api from '@/plugins/api'
import * as endpoints from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'
import { PAGE_SIZE_REPORT } from '@/configs/constants'

const store = 'report'

export default {
  state: () => ({
    reports: {},
    isLoadingReports: false
  }),
  actions: {
    async generateReport (context, { body, type }) {
      const query = new URLSearchParams(body)
      return await api.post(`${ endpoints[type].generateExcel }?${ query }`)
    },

    async getReports (context, params = {}) {
      context.commit('SET_DATA', { store, state: 'isLoadingReports', data: true })

      params.page_size = PAGE_SIZE_REPORT
      let getParams = params && Object.keys(params)
        .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)

      const response = await api.get(`${ endpoints.report.report }${ getParams ? `?${ getParams }` : '' } `)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'reports', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoadingReports', data: false })
      return response.data
    },

    async getReport (context, id) {
      const response = await api.get(endpoints.report.download(id), { responseType: 'blob' })
      if (await requestValidation(response)) return response
    }
  }
}
