export default [
  {
    path: '/onu-duplicate',
    name: 'onu-duplicate',
    meta: {
      access: ['view_duplicate_onu'],
      prevRoutes: ['onu']
    },
    component: () => import('@/views/OnuDuplicate/OnuDuplicate')
  }
]
