export default [
  {
    path: '/presets/new',
    name: 'presets-new',
    meta: {
      access: ['view_configonutemplate', 'add_configonutemplate', 'change_configonutemplate', 'delete_configonutemplate'],
      prevRoutes: ['preset']
    },
    component: () => import('@/views/Preset/Info')
  },
  {
    path: '/presets/:id',
    name: 'presets-info',
    meta: {
      access: ['view_configonutemplate', 'add_configonutemplate', 'change_configonutemplate', 'delete_configonutemplate'],
      prevRoutes: ['preset']
    },
    component: () => import('@/views/Preset/Info')
  },
  {
    path: '/presets',
    name: 'presets',
    meta: {
      access: ['view_configonutemplate']
    },
    component: () => import('@/views/Preset/Preset')
  },
  {
    path: '/actions/new',
    name: 'actions-new',
    meta: {
      access: ['view_configonutemplate', 'add_configonutemplate', 'change_configonutemplate', 'delete_configonutemplate'],
      prevRoutes: ['actions']
    },
    component: () => import('@/views/Preset/Info')
  },
  {
    path: '/actions/:id',
    name: 'actions-info',
    meta: {
      access: ['view_configonutemplate', 'add_configonutemplate', 'change_configonutemplate', 'delete_configonutemplate'],
      prevRoutes: ['actions']
    },
    component: () => import('@/views/Preset/Info')
  },
  {
    path: '/actions',
    name: 'actions',
    meta: {
      access: ['view_configonutemplate']
    },
    component: () => import('@/views/Preset/Preset')
  }
]
