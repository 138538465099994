import notify from "@ananasbear/notify";

export const handlerFormValidation = errors => {
  if (errors instanceof Array) {
    for (const error of errors) {
      notify.warning(error)
    }
    return
  }
  if (errors instanceof Object) {
    const errorObject = dispatchErrorObject(errors)
    
    for (const propertyName in errorObject) {
      if (errorObject[propertyName] instanceof Array) {
        errorObject[propertyName] = errorObject[propertyName][0]
      }
    }

    for (const propertyName in errorObject) {
      notify.warning(`${propertyName.charAt(0).toUpperCase() + propertyName.slice(1)}: ${errorObject[propertyName]}`)
    }
  }
}

function dispatchErrorObject (errorObject = {}) {
  const errorKeys = Object.keys(errorObject)
  if (errorKeys.length > 1) return errorObject
  const firstKeyName = errorKeys[0]

  // check is it single error
  const isArrayError = errorObject[firstKeyName] instanceof Array
  const isStringError = typeof errorObject[firstKeyName] === 'string'
  if (isArrayError || isStringError) return errorObject

  return dispatchErrorObject(errorObject[firstKeyName])
}
