/* eslint-disable vue/max-len */
import { i18n } from '@/plugins/vue-i18n'

const loss = 1
const notExist = 2
const synchronize = 3
const online = 4
const powerOff = 5
const deleted = 6
const offline = 7
const unregistered = 8
const reboot = 12

const onu = id => {
  switch (id) {
    case reboot:
      return {
        name: i18n.t('statuses.reboot'),
        color: 'warning',
        textColor: 'warning--text',
        background: '#ECFDF3'
      }
    case loss:
      return {
        name: i18n.t('statuses.loss'),
        color: 'loss-chip--circle',
        textColor: 'loss-chip--text',
        background: '#FFF1F3'
      }
    case notExist:
      return { name: i18n.t('statuses.notExist'), color: 'error', textColor: 'error--text', background: '#F2F4F7' }
    case synchronize:
      return {
        name: i18n.t('statuses.synchronize'),
        color: 'warning',
        textColor: 'warning--text',
        background: '#ECFDF3'
      }
    case online:
      return {
        name: i18n.t('statuses.online'),
        color: 'success-chip--circle',
        textColor: 'success-chip--text',
        background: '#ECFDF3'
      }
    case powerOff:
      return {
        name: i18n.t('statuses.powerOff'),
        color: 'powerOff-chip--circle',
        textColor: 'powerOff-chip--text',
        background: '#FFFAEB'
      }
    case deleted:
      return { name: i18n.t('statuses.deleted'), color: 'error', textColor: 'error--text', background: '#F2F4F7' }
    case offline:
      return {
        name: i18n.t('statuses.offline'),
        color: 'offline-chip--circle',
        textColor: 'offline-chip--text',
        background: '#F2F4F7'
      }
    case unregistered:
      return {
        name: i18n.t('statuses.unregistered'),
        color: 'unregistered-chip--circle',
        textColor: 'unregistered-chip--text',
        background: '#FFFAEB'
      }
    default:
      return { name: i18n.t('statuses.def'), color: 'warning', textColor: 'warning--text' }
  }
}

const adminOnline = [1, 'on']
const adminOffline = [2, 'off']

const admin = id => {
  if (adminOnline.includes(id)) return {
    name: 'Up',
    color: 'success-chip--circle',
    textColor: 'success-chip--text',
    background: '#ECFDF3',
    portStatus: '#12B76A'
  }
  else if (adminOffline.includes(id)) return {
    name: 'Down',
    color: 'offline-chip--circle',
    textColor: 'offline-chip--text',
    background: '#F2F4F7',
    portStatus: '#E4E7EC'
  }
  return { name: 'Undefined', color: 'warning', textColor: 'warning--text' }
}
const onuStatus = id => {
  if (adminOnline.includes(id)) return {
    name: 'Auto',
    color: 'success-chip--circle',
    textColor: 'success-chip--text',
    background: '#ECFDF3',
    portStatus: '#12B76A'
  }
  else if (adminOffline.includes(id)) return {
    name: 'Down',
    color: 'offline-chip--circle',
    textColor: 'offline-chip--text',
    background: '#F2F4F7',
    portStatus: '#E4E7EC'
  }
  return { name: 'Undefined', color: 'warning', textColor: 'warning--text' }
}
const migration = id => {
  switch (id) {
    case 1:
      return {
        name: i18n.t('statuses.planned'),
        color: 'offline-chip--circle',
        textColor: 'offline-chip--text',
        background: '#F2F4F7'
      }
    case 2:
      return {
        name: i18n.t('statuses.inProgress'),
        color: 'powerOff-chip--circle',
        textColor: 'powerOff-chip--text',
        background: '#FFFAEB'
      }
    case 3:
      return {
        name: i18n.t('statuses.finished'),
        color: 'success-chip--circle',
        textColor: 'success-chip--text',
        background: '#ECFDF3'
      }
    default:
      return { name: i18n.t('statuses.def'), color: 'warning', textColor: 'warning--text' }
  }
}

const linkOnline = 1
const linkOffline = 2

const link = id => {
  if (id === linkOnline) return {
    name: 'Up',
    color: 'success-chip--circle',
    textColor: 'success-chip--text',
    background: '#ECFDF3'
  }
  else if (id === linkOffline) return {
    name: 'Down',
    color: 'gray-500',
    textColor: 'offline-chip--text',
    background: '#F2F4F7'
  }
  return { name: 'Undefined', color: 'warning', textColor: 'warning--text' }
}

const adminPortOn = 1
const adminPortOff = 2

const adminPort = id => {
  if (id === adminPortOn) return { name: 'On', color: '#F2F4F7', textColor: 'text-success', background: 'offline' }
  else if (id === adminPortOff) return { name: 'Off', color: '#F2F4F7', textColor: 'text-error', background: 'offline' }
  return { name: 'Undefined', color: 'warning', textColor: 'warning--text' }
}

const activity = status => {
  return status ? {
      name: 'Active',
      shortName: 'ON',
      color: 'success-chip--circle',
      textColor: 'success-chip--text',
      background: '#ECFDF3',
      textColorShort: 'text-success'
    }
    : {
      name: 'No active',
      shortName: 'OFF',
      color: 'disabled--text',
      textColor: 'disabled--text',
      background: '#F2F4F7',
      textColorShort: 'text-error'
    }
}

const operationDown = 1
const operation10mHalf = 2
const operation10mFull = 3
const operation100mHalf = 4
const operation100mFull = 5
const operation1000m = 6
const operationAuto = 7
const operationUnknown = 6553

const operation = id => {
  switch (id) {
    case operationDown:
      return { text: 'Down', color: 'error' }
    case operation10mHalf:
      return { text: '10m/Half', color: 'error' }
    case operation10mFull:
      return { text: '10m/Full', color: 'error' }
    case operation100mHalf:
      return { text: '100m/Full', color: 'warning' }
    case operation100mFull:
      return { text: '100m/Full', color: 'warning' }
    case operation1000m:
      return { text: '1000m/Full', color: 'success' }
    case operationAuto:
      return { text: 'Auto', color: 'success' }
    case operationUnknown:
      return { text: 'Problem', color: 'error' }
    default:
      return { text: 'Undefined', color: 'warning' }
  }
}
const operationSwitchUp = 1
const operationSwitchDown = 2

const operationSwitch = id => {
  switch (id) {
    case operationSwitchUp:
      return { text: 'Up', color: 'success' }
    case operationSwitchDown:
      return { text: 'Down', color: 'error' }
    default:
      return { text: 'Undefined', color: 'warning' }
  }
}

const speedAuto = 1
const speed10mHalf = 2
const speed10mFull = 3
const speed100mHalf = 4
const speed100mFull = 5
const speed1000m = 6

const speed = id => {
  switch (id) {
    case speedAuto:
      return { text: 'Auto', color: 'success' }
    case speed10mHalf:
      return { text: '10m/Half', color: 'error' }
    case speed10mFull:
      return { text: '10m/Full', color: 'error' }
    case speed100mHalf:
      return { text: '100m/Full', color: 'warning' }
    case speed100mFull:
      return { text: '100m/Full', color: 'warning' }
    case speed1000m:
      return { text: '1000m/Full', color: 'success' }
    default:
      return { text: 'Down', color: 'error' }
  }
}

export default { onu, admin, link, adminPort, activity, operation, operationSwitch, speed, migration, onuStatus }
/* eslint-enable vue/max-len */
