import { auth } from '@/configs/endpoints'

const cbor = require('cbor-js')
const options = {
	tokenKey: 'Token',
	urls: auth
}

const apiModule = process.env.VUE_APP_API_MODULE

let api, auth2

const pluginOptions = {
  tokenKey: 'Token',
  tokenKeyResponse: 'token',
  urls: {
    totp: {
      create: ``,
      verify: ``,
      login: ``
    },
    u2f: {
      registration: {
        options: '',
        registration: ''
      },
      authorization: {
        options: '',
        authorization: ''
      }
    },
    general: {
      login: ``
    },
    phone: {
      login: ''
    },
    googleAuth: {
      convertToken: ``,
      gsiLogin: ''
    }
  }
}

export class Authorization {
  constructor(options) {
    if (options) {
      pluginOptions.tokenKey = options.tokenKey
      pluginOptions.tokenKeyResponse = options.tokenKeyResponse || 'token'
      pluginOptions.urls = options.urls
      if (options.apiModule || apiModule) {
        api = apiModule ? require(`${ process.env.VUE_APP_API_MODULE }`).default : options.apiModule
      } else throw Error('Module API not found, add superAPI module config in env as VUE_APP_API_MODULE')
    }
  }

  isAuth () {
    if (location.href.includes('https') && document.cookie.includes('TOKEN') &&
      !document.cookie.includes('TOKENTOTP') && !document.cookie.includes('TOKENU2F')) return true
    else return !!(!location.href.includes('https') && localStorage.getItem('TOKEN'))
  }

  /** return if exist AUTH TOTP */
  isTOTPExist () {
    return TOTP.isExist() && !Authorization.expiredTTLToken()
  }

  /** return if exist REGISTER TOTP */
  isTOTPCrateExist () {
    return TOTP.isCreateExist() && !Authorization.expiredTTLToken()
  }

  /** return if exist AUTH U2F */
  isU2FExist () {
    return !!(U2F.isExist() && !Authorization.expiredTTLToken())
  }

  /** return if exist REGISTER U2F */
  isU2FCrateExist () {
    if (U2F.isCreateExist() && !Authorization.expiredTTLToken()) {
      U2F.register()
      return true
    } else return false
  }

  getTOTPQR () {
    return TOTP.getQR()
  }

  authoCheck () {
    if (this.isU2FCrateExist()) return 'U2F create'
    else if (this.isU2FExist()) return 'U2F auth'
    else if (this.isTOTPCrateExist()) return 'TOTP create'
    else if (this.isTOTPExist()) return 'TOTP auth'
  }

  static deleteData () {
    localStorage.removeItem('TOKEN')
    localStorage.removeItem('isOutdatedRelease')
    document.cookie = 'TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  }

  static setToken (key, token, expires = '') {
    localStorage.removeItem('U2FData')
    localStorage.removeItem('TOKENU2F')
    localStorage.removeItem('TOKENTOTP')
    localStorage.removeItem('TOKENL')
    document.cookie = 'TOKENU2F=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    document.cookie = 'TOKENTOTP=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'

    localStorage.setItem('TOKEN', `${ key } ${ token }`)
    if (expires) document.cookie = `TOKEN=${ key } ${ token }; expires=${ expires }; samesite=strict;`
    else document.cookie = `TOKEN=${ key } ${ token }; samesite=strict; max-age=28800`
  }

  static setBearer2F (barer, type) {
    localStorage.setItem(`TOKEN${ type }`, `Bearer ${ barer }`)
    localStorage.setItem(`TOKENL`, new Date().getTime() + 600000)
    document.cookie = `TOKEN${ type }=Bearer ${ barer }; samesite=strict; max-age=600`
  }

  /** return bool- expired Token or not */
  static expiredTTLToken () {
    const TOKEN = Number(localStorage.getItem(`TOKENL`))

    if (TOKEN < new Date().getTime()) {
      localStorage.removeItem(`TOKENL`)
      TOTP.deleteData()
      U2F.deleteData()
    }

    return this.prototype.isAuth() ? false : TOKEN < new Date().getTime()
  }

  static checkAuthType (response, type) {
    return !!(response &&
      response.code === 200 &&
      response.data.type === type)
  }

  static checkTypeRegistration (response) {
    switch (true) {
      case (response.data.type !== response.data.required_authorization.type) &&
      response.data.required_authorization.type === 'TOTP' &&
      (!response.data.required_authorization.date ||
        new Date() >= new Date(response.data.required_authorization.date)):
        return { current: 'TOTP', required: 'TOTP' }

      case (response.data.type !== response.data.required_authorization.type) &&
      response.data.required_authorization.type === 'U2F' &&
      new Date() >= new Date(response.data.required_authorization.date):
        return { current: 'U2F', required: 'U2F' }

      case (response.data.type !== response.data.required_authorization.type) &&
      new Date() <= new Date(response.data.required_authorization.date) &&
      response.data.type === 'general':
        return { current: 'general', required: 'TOTP' }

      case (response.data.type !== response.data.required_authorization.type) &&
      new Date() <= new Date(response.data.required_authorization.date) &&
      response.data.type !== 'general':
        return { current: 'TOTP', required: 'U2F' }
    }
  }

  /** return func by type of action - registration or auth*/
  /* eslint-disable vue/max-len */
  needRegister (data, response) {
    const type = Authorization.checkTypeRegistration(response)

    if (type.current === 'TOTP' && type.required === 'TOTP') {
      Authorization.setBearer2F(response.data.bearer, 'TOTP')
      return TOTP.setRegistration(data, response)

    } else if (type.current === 'U2F' && type.required === 'U2F') {
      Authorization.setBearer2F(response.data.bearer, response.data.required_authorization.type)
      return U2F.setRegistration(data, response)

    } else if (type.current === 'general' && type.required === 'TOTP') {
      Authorization.setToken(pluginOptions.tokenKey, response.data[pluginOptions.tokenKeyResponse])
      return {
        type: response.data.type,
        requiredDate: response.data.required_authorization.date,
        requiredType: response.data.required_authorization.type,
        status: true,
        notification: `Need ${ response.data.required_authorization.type } to ${ response.data.required_authorization.date }`
      }

    } else if (type.current === 'TOTP' && type.required === 'U2F') {
      Authorization.setBearer2F(response.data.bearer, response.data.type)
      return {
        type: response.data.type,
        requiredDate: response.data.required_authorization.date,
        requiredType: response.data.required_authorization.type,
        status: true,
        notification: `Need ${ response.data.required_authorization.type } to ${ response.data.required_authorization.date }`
      }
    }
    /* eslint-enable vue/max-len */
  }

  /**
   * Start auth by credits
   * In response will be data for auth bu general, TOTP or U2F
   * */
  byCredits(data) {
    TOTP.deleteData()
    U2F.deleteData()
    Authorization.deleteData()
    return api.post(`${pluginOptions.urls.general.login}`, { body: data }).then(response => {
      switch (true) {
        /* General */
        case Authorization.checkAuthType(response, 'general'):
          console.log('General')
          if (response.data.type === response.data.required_authorization.type) {
            Authorization.setToken(pluginOptions.tokenKey, response.data[pluginOptions.tokenKeyResponse])
            return { type: 'general', status: true }
          } else return this.needRegister(data, response)

        /* TOTP */
        case Authorization.checkAuthType(response, 'TOTP'):
          console.log('TOTP')
          if (response.data.type === response.data.required_authorization.type) {
            Authorization.setBearer2F(response.data.bearer, 'TOTP')
            return { type: 'TOTP', status: true }
          } else return this.needRegister(data, response)

        /* U2F */
        case Authorization.checkAuthType(response, 'U2F'):
          console.log('U2F')
          Authorization.setBearer2F(response.data.bearer, 'U2F')
          U2FC.setData({ request: response.data.barer })
          return { type: 'U2F', status: true }

        case !!response.data[pluginOptions.tokenKeyResponse]:
          Authorization.setToken(pluginOptions.tokenKey, response.data[pluginOptions.tokenKeyResponse])
          return { type: 'general', status: true }

        /* Your password has expired, or you are logging in for the first time */
        case (response.code === 401 && response.data.detail === 'User should change password'):
          return { status: false, error: response.data.detail }

        default: return response
      }
    })
  }

  byPhone(data, timer) {
    return PHONE.auth(data, timer)
  }

  byTOTP (data) {
    return this.isTOTPCrateExist() ? TOTP.verify(data) : TOTP.auth(data)
  }

  byU2F () {
    return this.isU2FCrateExist() ? U2F.register() : U2F.auth()
  }

  initGoogleGSI (clientId, btn, btnOptions, redirectURL, callback) {
    return GOOGLE.initGSI(clientId, btn, btnOptions, redirectURL, callback)
  }
}

class TOTPC extends Authorization {
  constructor() {
    super()
  }

  isExist () {
    if (location.href.includes('https') && document.cookie.includes('TOKENTOTP')) return true
    else return !!(!location.href.includes('https') && localStorage.getItem('TOKENTOTP'))
  }

  isCreateExist () {
    return !!localStorage.getItem('TOTPData')
  }

  static setData (data) {
    localStorage.removeItem('TOTPData')
    localStorage.setItem('TOTPData', JSON.stringify(data))
  }

  static getData () {
    return JSON.parse(localStorage.getItem('TOTPData'))
  }

  deleteData () {
    localStorage.removeItem('TOTPData')
    localStorage.removeItem('TOKENTOTP')
    localStorage.removeItem('TOKENL')
    document.cookie = 'TOKENTOTP=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  }

  static deleteCreteData () {
    localStorage.removeItem('TOTPData')
  }

  getQR () {
    return TOTPC.getData().qr
  }

  setRegistration (data, response) {
    data.required = response.data.required_authorization
    return this.register().then(result => {
      data.qr = result
      TOTPC.setData(data)

      return { type: 'TOTP', status: false, data: { date: response.data.required_authorization.date, qr: result }}
    })
  }

  register () {
    return new Promise((resolve, reject) => {

      if (Authorization.expiredTTLToken()) return reject({status: false, error: 'Token expired'})
      // let data = TOTPC.getData()

      return api.post(pluginOptions.urls.totp.create).then(response => {
        if (response) {
          return resolve(response.data.qr_code)
        } else return reject('error qr')
      })
    })
  }

  verify (token) {
    return new Promise((resolve, reject) => {
      if (Authorization.expiredTTLToken()) return reject({ status: false, error: 'Token expired' })

      return api.post(pluginOptions.urls.totp.verify, { body: token }).then(response => {
        if (response && response.code === 200) {
          TOTPC.deleteCreteData()
          return resolve({ type: 'verify', status: true })
        }
        else return reject({ type: 'verify', status: false, error: `Request error: ${ response.code }` })
      })
        .catch(e => {
          console.error(e)
          return reject({ type: 'verify', status: false, error: `Request error: ${ e }` })
        })
    })
  }

  auth (data) {
    return new Promise((resolve, reject) => {
      if (Authorization.expiredTTLToken()) return reject({ status: false, error: 'Token expired' })

      const BODY = { token: data[pluginOptions.tokenKeyResponse] }
      return api.post(pluginOptions.urls.totp.login, { body: BODY }).then(response => {
        if (response && response.code === 200) {
          Authorization.setToken(pluginOptions.tokenKey, response.data[pluginOptions.tokenKeyResponse])
          return resolve({ type: 'auth', status: true })
        }
        else return reject({ type: 'auth', status: false, error: `Request error: ${ response.code }` })
      })
        .catch(e => {
          console.error(e)
          return reject({ type: 'auth', status: false, error: `Request error: ${ e }` })
        })
    })
  }
}

class U2FC extends Authorization {
  constructor() {
    super()
    this.cbor = require('cbor-js')
  }

  isCreateExist () {
    return document.cookie.includes('TOKENU2F') && !localStorage.getItem('U2FData')
  }

  isExist () {
    return !!(localStorage.getItem('U2FData') && document.cookie.includes('TOKENU2F'))
  }

  static setData (data) {
    localStorage.removeItem('U2FData')
    localStorage.setItem('U2FData', JSON.stringify(data))
  }

  static getData () {
    return JSON.parse(localStorage.getItem('U2FData'))
  }

  deleteData () {
    localStorage.removeItem('U2FData')
    localStorage.removeItem('TOKENU2F')
    localStorage.removeItem('TOKENL')
    document.cookie = 'TOKENU2F=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  }

  setRegistration () {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      let optionsEncode = await api.get(pluginOptions.urls.registration.options)
      optionsEncode = await optionsEncode.arrayBuffer()

      this.options = cbor.decode(optionsEncode)

      //set options for registration only u2f
      this.options.publicKey.attestation = 'none'
      this.options.publicKey.authenticatorSelection = {
        authenticatorAttachment: 'cross-platform',
        userVerification: 'discouraged',
        requireResidentKey: false
      }

      try {
        const credential = await navigator.credentials.create(this.options)

        const credentialEncode = cbor.encode({
          'attestationObject': new Uint8Array(credential.response.attestationObject),
          'clientDataJSON': new Uint8Array(credential.response.clientDataJSON)
        })

        const optionsFetch = {
          headers: {
            'Content-Type': 'application/cbor'
          },
          body: credentialEncode
        }

        const response = await api.post(pluginOptions.urls.registration.registration, optionsFetch)
        if ([200, 201].includes(response.code)) {
          resolve({ status: true })
        }
        else reject({ status: false, error: `Request error: ${response.code}`})
      } catch (e) {
        reject({ status: false, error: 'Key not detected'})
      }
    })
  }

  auth () {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const fetchOpt = {
        headers: {
          'Content-Type': 'application/cbor'
        },
        requestType: 'cbor',
        responseType: 'arrayBuffer'
      }
      let optionsEncode = await api.get(pluginOptions.urls.u2f.authorization.options, fetchOpt)
      // optionsEncode = await optionsEncode.arrayBuffer()

      const optionsAuth = await this.cbor.decode(optionsEncode.data)

      if (optionsAuth.publicKey.allowCredentials.length) {
        //set options for authorization only by u2f
        optionsAuth.publicKey.allowCredentials.map(i => {
          i.transports = ['usb', 'nfc']
        })

        try {
          const credential = await navigator.credentials.get(optionsAuth)

          const credentialEncode = this.cbor.encode({
            "credentialId": new Uint8Array(credential.rawId),
            "authenticatorData": new Uint8Array(credential.response.authenticatorData),
            "clientDataJSON": new Uint8Array(credential.response.clientDataJSON),
            "signature": new Uint8Array(credential.response.signature)
          })

          console.log(credentialEncode)

          const optionsFetch = {
            headers: {
              'Content-Type': 'application/cbor'
            },
            body: credentialEncode,
            requestType: 'cbor'
          }
          const response =  await api.post(pluginOptions.urls.u2f.authorization.authorization, optionsFetch)
          if ([200, 201].includes(response.code)) {
            Authorization.setToken(pluginOptions.tokenKey, response.data[pluginOptions.tokenKeyResponse])

            resolve({ type: 'U2F', status: true })
          }
          else reject({ status: false, error: `Request error: ${response.code}`})
        } catch (e) {
          reject(e)
        }
      } else reject({ type: 'U2F', status: false, error: 'U2F not detected'})

    })
  }
}

class PHONEC extends Authorization {
  static closeTimeout(timer) {
    clearInterval(timer.timer)
  }

  static setTimeoutResend (timer) {
    timer.timeToResend = 60
    timer.timer = setInterval(() => {
      if (timer.timeToResend === 0 ) this.closeTimeout(timer)
      else timer.timeToResend--
    }, 1000)
  }

  auth (data, timer) {
    return api.post(pluginOptions.urls.phone.login, { body: data }).then(response => {
      PHONEC.closeTimeout(timer)

      switch (true) {
        case response && !!response.data[pluginOptions.tokenKeyResponse]:
        case response && response.data.status:
          Authorization.setToken(pluginOptions.tokenKey, response.data[pluginOptions.tokenKeyResponse])
          return { code: false, auth: true}
        case response && (response.data.code ||
          ( response.data.description && response.data.description.includes('send'))):
          PHONEC.setTimeoutResend(timer)

          return { code: true, auth: false }
        default: return false
      }
    })
  }
}

class GOOGLEC extends Authorization {
  constructor() {
    super()
    this.client_id = null //process.env.VUE_APP_OAUTH_CLIENT_ID
    // this.backend_client_id = process.env.VUE_APP_BACKEND_CLIENT_ID
    this.redirectURL = null
  }

  /**
   * Google oauth initialization
   */
  init () {
    console.log('INIT GOOGLE', this.client_id)

    if (!window.gapi) return console.warn('"https://apis.google.com/js/api:client.js" needs to be included as a <script>')
    if (!this.client_id) return console.warn('Property @client_id must be declared in .env as VUE_APP_OAUTH_CLIENT_ID')

    window.gapi.load('auth2', () => {
      auth2 = window.gapi.auth2.init({ client_id: this.client_id })
    })
  }

  /**
   * Google oauth initialization for GSI auth
   */
  initGSI (clientId, btn, btnOptions, redirectURL, callback) {
    this.client_id = clientId
    if (this.client_id) {
      this.redirectURL = redirectURL

      window.google.accounts.id.initialize({
        client_id: this.client_id,
        callback: response => {
          this.handleCredentialResponse(response, callback)
        },
        auto_select: true
      })
      window.google.accounts.id.renderButton(btn, btnOptions)
    } else return console.warn('Property @client_id must be declared in .env as VUE_APP_OAUTH_CLIENT_ID')

  }

  /**
   * Sign in with Google and convert access token from oauth to authorization token GSI
   */
  async handleCredentialResponse (response, callback) {
    console.log(response)
    const body = {
      auth_token: response.credential
    }
    const result = await api.post(pluginOptions.urls.googleAuth.gsiLogin, { body })

    if ([200, 201].includes(result?.code)) {
      Authorization.setToken(pluginOptions.tokenKey, result.data.token)
      if (this.redirectURL) location = this.redirectURL
    } else {
      callback(await result.data.json())
    }
  }

  /**
   * Sign in with Google and convert access token from oauth to authorization token
   * @returns {Promise<unknown>}
   */
  signIn () {
    return new Promise((resolve, reject) => {
      if (
        !pluginOptions.urls
          || !pluginOptions.urls.googleAuth
          || !pluginOptions.urls.googleAuth.convertToken
      ) return reject('Convert token url wasnʼt declared')
      if (!this.client_id) {
        return reject('Property @client_id must be declared in .env as VUE_APP_OAUTH_CLIENT_ID')
      }
      if (!auth2) return reject('Make gapi initialization first')

      return auth2.signIn().then(async user => {
        if (!user) return reject('User wasnʼt found')

        let token = ''
        Object.values(user).forEach(value => { // Object property with access_token change every month by google
          if (typeof value === 'object' && value.access_token) token = value.access_token
        })
        const body = {
          grant_type: 'convert_token',
          client_id: this.client_id,
          backend: 'google-oauth2',
          token
        }

        const response = await api.post(pluginOptions.urls.googleAuth.convertToken, { body })
        if ([200, 201].includes(response?.code)) {
          Authorization.setToken(
            pluginOptions.tokenKey,
            response.data.access_token,
            GOOGLEC.getExpiredDate(response.data.expires_in)
          )
          return resolve({ status: true })
        } else return reject(`Request error: ${response.code}`)
      })
    })
  }

  /**
   * Returns the expiration date
   * @param expires_in
   * @returns {string}
   */
  static getExpiredDate (expires_in = 1) {
    const now = new Date()
    now.setTime(now.getTime() + (expires_in * 1000))
    return now.toUTCString()
  }
}

export const TOTP = new TOTPC()
export const U2F = new U2FC()
export const PHONE = new PHONEC()
export const GOOGLE = new GOOGLEC()

export const logout = () => {
  Object.entries(localStorage)
    .map(key => {
      if (key[0].includes('TOKEN')) {
        localStorage.removeItem(key[0])
        localStorage.removeItem('isOutdatedRelease')
        document.cookie = `${ key[0] }=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
      }
    })
}

const authorization = new Authorization(options)
export default authorization
