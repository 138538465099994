import Vue from 'vue'
import Vuetify from 'vuetify'
import ChevronDown from '@/components/Icons/ChevronDown'
import PrevArrow from '@/components/Icons/PrevArrow'
import NextArrow from '@/components/Icons/NextArrow'
import Info from '@/components/Icons/Info'
import EyeOff from '@/components/Icons/EyeOff'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#FF512A',
        secondary: '#667085',
        error: '#F04438',
        'gray--900': '#344054',
        black: '#101828'
      }
    }
  },
  icons: {
    values: {
      chevronDown: {
        component: ChevronDown,
        props: {
          name: 'chevronDown'
        }
      },
      prevArrow: {
        component: PrevArrow,
        props: {
          name: 'prevArrow'
        }
      },
      nextArrow: {
        component: NextArrow,
        props: {
          name: 'nextArrow'
        }
      },
      info: {
        component: Info,
        props: {
          name: 'info'
        }
      },
      eyeOff: {
        component: EyeOff,
        props: {
          name: 'eyeOff'
        }
      }
    }
  }
})
