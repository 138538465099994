import api from '@/plugins/api'
import { license } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'

const store = 'license'

export default {
  state: () => ({
    license: {},
    isNeedToPay: false,
    isShowBillNotification: false,
    isShowBillNav: false,
    isShowBillPopup: false
  }),
  actions: {
    toggleBillNotification ({ commit }, { isShowNotify, isShowPopup, isNeedToPay, isShowNav }) {
      if (typeof isShowNotify === 'boolean')
        commit('SET_DATA', { store, state: 'isShowBillNotification', data: isShowNotify })

      if (typeof isShowNav === 'boolean')
        commit('SET_DATA', { store, state: 'isShowBillNav', data: isShowNav })

      if (typeof isShowPopup === 'boolean') {
        commit('SET_DATA', { store, state: 'isShowBillPopup', data: isShowPopup })
      }

      if (typeof isNeedToPay === 'boolean')
        commit('SET_DATA', { store, state: 'isNeedToPay', data: isNeedToPay })
    },
    async _initLicense ({ commit, rootState, dispatch }, response) {
      const isSuccess = await requestValidation(response)
      if (!isSuccess) return {}

      const isNeedToPay = response?.data?.is_blocked
      dispatch('toggleBillNotification', {
        isShowNav: isNeedToPay,
        isShowNotify: isNeedToPay,
        isNeedToPay
      })
      commit('SET_DATA', { store, state: 'license', data: response?.data })
      if (!response?.data?.user_address) {
        return response.data
      }
      const locationDefault = rootState.app.location
      const locationKeys = response?.data?.user_address?.split(',')

      const location = {}
      Object.keys(locationDefault).forEach((key, index) => {
        if (locationKeys[index])
          location[key] = locationKeys[index]
      })
      commit('SET_DATA', { store: 'app', state: 'location', data: location })

      return response.data
    },
    async getLicense ({ dispatch }) {
      return dispatch('_initLicense', await api.get(license.license))
    },
    async updateLicenseStatus ({ dispatch }) {
      return dispatch('_initLicense', await api.post(license.license))
    }
  }
}
