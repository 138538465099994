<template>
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.16669 6.99999H12.8334M12.8334 6.99999L7.00002 1.16666M12.8334 6.99999L7.00002 12.8333"
            stroke="#667085"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
  name: "NextArrow"
};
</script>
