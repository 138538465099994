export default [
  {
    path: '/olt',
    name: 'olt',
    meta: {
      access: ['view_olt', 'add_olt', 'change_olt', 'delete_olt']
    },
    component: () => import('@/views/Olt/Olt')
  },
  {
    path: '/olt/:id',
    name: 'olt-info',
    meta: {
      access: ['view_olt', 'add_olt', 'change_olt', 'delete_olt'],
      prevRoutes: ['olt']
    },
    component: () => import('@/views/Olt/Info/Info')
  },
  {
    path: '/olt/:id/edit',
    name: 'olt-edit',
    meta: {
      access: ['change_olt', 'delete_olt']
    },
    component: () => import('@/views/Olt/Form')
  }
]
