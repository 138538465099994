import api from '@/plugins/api'
import { access as endpoint } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'
import { getGroupPermissions } from '@/configs/groupPermissions.methods'
import { PAGE_SIZE_GROUP } from '@/configs/constants'

const store = 'access'

export default {
  state: () => ({
    groups: [],
    isLoadingGroups: false,
    groupInfo: {},
    isLoadingInfoGroup: false,
    permissions: {}
  }),
  actions: {
    async getGroups (context, params = {}) {
      context.commit('SET_DATA', { store, state: 'isLoadingGroups', data: true })

      params.page_size = PAGE_SIZE_GROUP
      let getParams = params && Object.keys(params)
        .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)

      const response = await api.get(`${ endpoint.group }${ getParams ? `?${ getParams }` : '' } `)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'groups', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoadingGroups', data: false })
    },

    async getInfoGroup (context, id) {
      context.commit('SET_DATA', { store, state: 'isLoadingInfoGroup', data: true })

      const response = await api.get(endpoint.actionGroup(id))
      const isResult = await requestValidation(response)

      if (isResult) {
        context.commit('SET_DATA', { store, state: 'groupInfo', data: response.data })
        context.commit('SET_DATA', { store, state: 'isLoadingInfoGroup', data: false })
      }
      return response.code
    },

    async postGroup (context, body) {
      const response = await api.post(endpoint.group, { body: body })
      return await requestValidation(response)
    },

    async patchGroup (context, body) {
      const response = await api.patch(endpoint.actionGroup(body.id), { body: body })
      const isResult = await requestValidation(response)
      if (isResult) {
        context.commit('SET_DATA', { store, state: 'groupInfo', data: response.data })
      }
      return isResult
    },

    async getPermissions (context) {
      const response = await api.get(endpoint.permission)

      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'permissions', data: getGroupPermissions(response.data) })
      }
    },

    async deleteGroup (context, id) {
      const response = await api.delete(endpoint.actionGroup(id))
      return await requestValidation(response)
    }
  },
  getters: {
    groupById: state => id => state.groups.results?.find(item => Number(item.id) === Number(id)),
    permissionById: state => id => state.permissions.find(item => Number(item.id) === Number(id))
    // permissionByCodename: state => codename => state.permissions.find(item => {
    //   console.log(codename)
    //   console.log(item)
    //   return item.codename === codename
    // }),
  }
}
