const pluginOptions = {
  publicPages: ['/login', '/404'],
  tokenKey: 'TOKEN',
  loginPath: '/login',
  cache: {
    use: false,
    age: null
  }
}

const MAIN_URL = process.env.VUE_APP_API || process.env.REACT_APP_API
if (!MAIN_URL) throw Error('VUE_APP_API not found, add api host in env as VUE_APP_API')
let errorsModule
let isReturnFailedBody

const HEADERS = {}
const OPTIONS = {}

export class API {
  constructor(options) {
    if (options) {
      if (options.publicPages) (pluginOptions.publicPages = options.publicPages)
      if (options.tokenKey) (pluginOptions.tokenKey = options.tokenKey)
      if (options.loginPath) (pluginOptions.loginPath = options.loginPath)
      if (options.cache) (pluginOptions.cache = options.cache)
      if (options.processingError) errorsModule = options.processingError
      if (options.isReturnFailedBody) isReturnFailedBody = options.isReturnFailedBody
    }

    HEADERS.json = {
      Accept : 'application/json',
      'Content-Type': 'application/json'
    }

    HEADERS.formData = {
      Accept : '*/*'
    }

    OPTIONS.responseType = 'json'
    OPTIONS.requestType = 'json'

    OPTIONS.headers = {
      'Access-Control-Request-Headers': '*'
    }

    if (pluginOptions.cache.use) {
      OPTIONS.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate'
      if (pluginOptions.cache.age) {
        OPTIONS.headers['Cache-Control'] = `max-age=${ pluginOptions.cache.age }`
      }
    }
  }

  static async setFetchOption(url, clientOptions) {
    const fetchOptions = { ...OPTIONS, ...clientOptions}

    if (clientOptions.body && clientOptions.body.toString().includes('FormData')) {
      fetchOptions.requestType = 'formData'
    }

    //set option by type
    if (fetchOptions.requestType === 'json') {
      fetchOptions.headers = { ...fetchOptions.headers, ...HEADERS.json}
    } else if (clientOptions.headers) fetchOptions.headers = { ...clientOptions.headers }

    //set body
    if (clientOptions.body) {
      if (fetchOptions.requestType === 'formData') {
        fetchOptions.body = clientOptions.body
        fetchOptions.headers = { ...fetchOptions.headers, ...HEADERS.formData }
      } else if (fetchOptions.requestType === 'json') fetchOptions.body = JSON.stringify(clientOptions.body)
      else fetchOptions.body = clientOptions.body
    }

    //set Authorization headers
    if (!(clientOptions && clientOptions.headers && clientOptions.headers.Authorization)) {
      document.cookie.split(';').forEach(i => {
        if (i.includes('TOKEN')) fetchOptions.headers.Authorization = i.split('=')[1]
      })
    }

    if (process.env.VUE_APP_TZ_HEADER === 'true') {
      fetchOptions.headers['X-TzName'] = Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    // set uri
    const uri = url.slice(0, 4) === 'http' ? url : `${ MAIN_URL }${ url }`

    return await this.fetchRequest(uri, fetchOptions)
  }

  static async fetchRequest (url, options) {
    const response = await fetch(url, options)
    const code = response.status

    if (code === 401) this.prototype.logout()
    if (code >= 200 && code <= 299 && code !== 204) {
      const data = await response[options.responseType]()
      return { code, data, headers: response.headers }
    } else {
      errorsModule && errorsModule(code, response)
      let data = response
      if (isReturnFailedBody && response.body) {
        data = await response[options.responseType]()
      }
      return { code, data }
    }
  }

  get(url, clientOptions) {
    const opt = {
      method: 'GET'
    }
    const options = {...opt, ...clientOptions}

    return API.setFetchOption(url, options)
  }

  post(url, clientOptions) {
    const opt = {
      method: 'POST'
    }
    const options = {...opt, ...clientOptions}

    return API.setFetchOption(url, options)
  }

  put(url, clientOptions) {
    const opt = {
      method: 'PUT'
    }
    const options = {...opt, ...clientOptions}

    return API.setFetchOption(url, options)
  }

  delete(url, clientOptions = null) {
    const opt = {
      method: 'DELETE'
    }
    const options = {...opt, ...clientOptions}

    return API.setFetchOption(url, options)
  }

  patch(url, clientOptions) {
    const opt = {
      method: 'PATCH'
    }
    const options = {...opt, ...clientOptions}

    return API.setFetchOption(url, options)
  }

  head(url, clientOptions) {
    const opt = {
      method: 'HEAD'
    }
    const options = {...opt, ...clientOptions}

    return API.setFetchOption(url, options)
  }

  logout () {
    Object.entries(localStorage)
      .map(key => {
        if (key[0].includes('TOKEN')) {
          localStorage.removeItem(key[0])
          localStorage.removeItem('isOutdatedRelease')
          document.cookie = `${ key[0] }=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
        }
      })
    if (!pluginOptions.publicPages.includes(location.pathname)) {
      let oldPath = encodeURIComponent(window.location.pathname)

      window.location = pluginOptions.loginPath + '?path=' + oldPath
    }
  }
}

const api = new API()

export default api
