<template lang="pug">
  v-tooltip(
    v-if="isNeedToPay"
    right
    open-on-hover
  )
    template(#activator="{ on, attrs }")
      img(:src="cloudOff" v-bind="attrs" v-on="on").ml-2.cursor-pointer
    span {{ $t('offlineTooltip') }}

</template>
<script>
import { mapState } from 'vuex'
import cloudOff from '@/assets/icons/cloud-off.svg'

export default {
  data () {
    return {
      cloudOff
    }
  },
  computed: {
    ...mapState({
      isNeedToPay: state => state.license.isNeedToPay
    })
  }
}

</script>
