import api from '@/plugins/api'
import { olt as endpoint } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'
import { PAGE_SIZE_OLT } from '@/configs/constants'

const store = 'olt'

export default {
  state: () => ({
    olt: { results: [] },
    infoOlt: {},
    isLoadingAllOlt: false,
    isLoadingInfoOlt: false,
    tasks: { results: [] },
    openedTask: null
  }),
  actions: {
    async getAllOlt (context, _params = {}) {
      const { isAll, ...params } = _params

      if (isAll)
        context.commit('SET_DATA', { store, state: 'isLoadingAllOlt', data: true })
      if (!isAll)
        params.page_size = PAGE_SIZE_OLT
      let getParams = params && Object.keys(params)
        .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)
      const response = await api.get(`${ endpoint.olt }${ getParams ? `?${ getParams }` : '' } `)
      if (await requestValidation(response) && isAll) {
        context.commit('SET_DATA', { store, state: 'olt', data: response.data })
      }
      if (isAll)
        context.commit('SET_DATA', { store, state: 'isLoadingAllOlt', data: false })
      return response.data
    },
    async getInfoOlt (context, id) {
      context.commit('SET_DATA', { store, state: 'isLoadingInfoOlt', data: true })

      const response = await api.get(endpoint.action(id))
      const isResult = await requestValidation(response)

      if (isResult) {
        response.data.slots.sort((a, b) => a.slot_number - b.slot_number)
        response.data.slots.forEach(slot => {
          slot.ports.sort((a, b) => {
            return a.port_number - b.port_number
          })
          slot.ports.forEach(port => {
            port.slot_number = slot.slot_number
          })
        })

        // context.state.tasks?.results.forEach(task => {
        //   if (task.olt_name === response.data.name) {
        //     context.commit('SET_DATA', { store, state: 'openedTask', data: task.task_id })
        //   }
        // })

        context.commit('SET_DATA', { store, state: 'infoOlt', data: response.data })
        context.commit('SET_DATA', { store, state: 'isLoadingInfoOlt', data: false })
      }
      return response.code
    },
    async postOlt (context, body) {
      const response = await api.post(endpoint.olt, { body: body })
      const isResult = await requestValidation(response)
      if (isResult) {
        context.commit('SET_DATA', { store, state: 'olt', data: response.data })
      }
      return { isResult, data: response.data }
    },
    async patchOlt (context, body) {
      const response = await api.patch(endpoint.action(body.id), { body: body })
      const isResult = await requestValidation(response)
      if (isResult) {
        await context.dispatch('getInfoOlt', body.id)
        await context.dispatch('getAllOlt', { page: 1 })
      }
      return { isResult, data: body }
    },
    async deleteOlt (context, id) {
      const response = await api.delete(endpoint.action(id))
      return await requestValidation(response)
    },
    async getInfoOltRealTime (context, id) {
      const response = await api.get(endpoint.refreshInfo(id))

      if (await requestValidation(response)) {
        response.data.slots.sort((a, b) => a.slot_number - b.slot_number)
        response.data.slots.forEach(slot => {
          slot.ports.sort((a, b) => {
            return a.port_number - b.port_number
          })
        })

        context.commit('SET_DATA', { store, state: 'infoOlt', data: response.data })
        return true
      } else return false
    },

    async getOltTasks (context, params) {
      let getParams = new URLSearchParams()
      getParams.append('page', params.page ? String(params.page) : '1')
      getParams.append('olt_id', String(params.olt_id))
      getParams.append('add_onu_tasks', String(params.add_onu_task))
      getParams.append('page_size', '20')

      const response = await api.get(`${ endpoint.task }?${ getParams }`)

      const isResult = await requestValidation(response)
      isResult && context.commit('SET_DATA', { store, state: 'tasks', data: response.data })
      return isResult
    },

    async syncOnu (context, data) {
      // openingTask
      // context.commit('SET_DATA', { store, state: 'openingTask', data: data.id })

      const response = await api.post(endpoint.syncAllOnu(data.id), { body: data.body })
      if (await requestValidation(response)) return response

    },

    async syncOnuCansel (context, data) {
      const response = await api.post(endpoint.syncOnuTaskCancel(data.id), { body: data.body })
      if (await requestValidation(response)) return response
    },

    async syncAllOnuByTask (context, id) {
      return await api.get(endpoint.syncOnuTask(id))
    },

    async testConnectionNewOlt (context, data) {
      const response = await api.post(endpoint.checkConnectionNew, { body: data })
      return await requestValidation(response) ? response.data : {}
    },

    async testConnectionOlt (context, id) {
      const response = await api.get(endpoint.checkConnection(id))
      return await requestValidation(response) ? response.data : {}
    },

    async patchOltPort (context, { params, body }) {
      const response = await api.patch(endpoint.oltPort(params), { body })
      const isResult = await requestValidation(response)
      if (isResult) {
        await context.dispatch('getInfoOlt', params.id)
      }
      return isResult
    }

  },
  getters: {
    oltById: state => id => state.olt.results?.find(item => Number(item.id) === Number(id))
  }
}
