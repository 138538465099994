import api from '@/plugins/api.js'
import { task as endpoints } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'
import { PAGE_SIZE_TASK } from '@/configs/constants'

const store = 'periodicTask'

export default {
  state: () => ({
    tasks: [],
    infoTask: {},
    isLoad: false,
    isViewForm: false
  }),
  actions: {
    async getPeriodicTasks (context, params = {}) {
      context.commit('SET_DATA', { store, state: 'isLoad', data: true })

      params.page_size = PAGE_SIZE_TASK
      let getParams = params && Object.keys(params)
          .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)

      const response = await api.get(`${ endpoints.task }${ getParams ? `?${ getParams }` : ''} `)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'tasks', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoad', data: false })
    },
    async getInfoTask (context, body) {
      context.commit('SET_DATA', { store, state: 'isLoad', data: true })

      const response = await api.get(endpoints.action(body.id))
      const isResult = await requestValidation(response)
      if (isResult) {
        context.commit('SET_DATA', { store, state: 'infoTask', data: response.data })
      }

      context.commit('SET_DATA', { store, state: 'isLoad', data: false })
    },
    async patchTask (context, body) {
      const response = await api.patch(endpoints.action(body.id), { body: body })
      const isResult = await requestValidation(response)
      if (isResult) {
        context.dispatch('getPeriodicTasks')
        context.commit('SET_DATA', { store, state: 'infoTask', data: response.data })
      }
      return isResult
    }
  },
  getters: {
    taskById: state => id => state.tasks.results.find(item => Number(item.id) === Number(id))
  }
}
