import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import { access } from '@/configs/permissions'
import olt from '@/router/olt.routes'
import switchRoute from '@/router/switch.routes'
import onu from '@/router/onu.routes'
import preset from '@/router/presets.routes'
import periodicTask from '@/router/periodicTask.routes'
import user from '@/router/users.routes'
import accessRoutes from '@/router/access.routes'
import onuDuplicate from '@/router/onuDuplicate.routes'
import report from '@/router/report.routes'
import migration from '@/router/migration.routes'
import log from '@/router/log.routes'
import building from '@/router/building.routes'
import integration from '@/router/integration.routes'

Vue.use(Router)

const routes = [
  ...integration,
  ...building,
  ...olt,
  ...switchRoute,
  ...onu,
  ...preset,
  ...periodicTask,
  ...user,
  ...accessRoutes,
  ...onuDuplicate,
  ...migration,
  ...report,
  ...log,
  {
    path: '*',
    name: 'all',
    redirect: '/404'
  },
  {
    path: '/404',
    name: 'not-found',
    meta: {
      access: 'public'
    },
    component: () => import('@/views/Page404')
  },
  {
    path: '/',
    name: 'main',
    meta: {
      access: 'public'
    },
    redirect: '/dashboard'
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      access: 'public'
    },
    component: () => import('@/views/Dashboard/Dashboard')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      public: true
    },
    component: () => import('@/views/Login')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      access: 'public'
    },
    component: () => import('@/views/Profile/Profile')
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior (to, from, savedPosition) {
    if (to.hash && from.hash && to.path === from.path) {
      return {
        selector: to.hash
      }
    }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if (from.name === to.name && JSON.stringify(from.query) !== JSON.stringify(to.query)) {
    if (!to.params.id || Number(from.params.id) === Number(to.params.id))
      next()
    else
      checkPrevPath(to, from, next)
  } else {
    const isAuth = Vue.prototype.$auth.isAuth()
    const profile = store.state.profile.user

    if (isAuth) {
      if (Object.keys(profile).length) routeToValidation(to, from, next)
      else {
        store.commit('SET_DATA', { store: 'app', state: 'isLoad', data: true })
        store.dispatch('getProfile')
          .then(() => routeToValidation(to, from, next))
      }
    } else {
      store.commit('SET_DATA', { store: 'app', state: 'isLoad', data: false })

      const oldPath = encodeURIComponent(window.location.pathname)
      if (to.name === 'login') {
        next()
        return
      }
      let nextPath = '/login'
      if (window.location.pathname !== '/')
        nextPath += `?path=${ oldPath }`
      next(nextPath)
    }
  }
})

const routeToValidation = (to, from, next) => {
  if (to.name === 'not-found' || to.name === 'server-error') next()
  else if (to.meta.public) next('/')
  else access(to.meta.access) ? checkPrevPath(to, from, next) : next('/404')
}

const checkPrevPath = (to, from, next) => {
  const ignorePath = ['edit', 'create', 'new']
  if (ignorePath.some(i => to.path.includes(i) || from.path.includes(i))) {
    next()
    return
  }

  const keys = [from.name, 'allRoutes']
  if (to.meta.prevRoutes?.some(i => keys.includes(i))) {
    if (sessionStorage.getItem(from.path) !== to.fullPath)
      sessionStorage.setItem(to.path, from.fullPath)
  } else {
    sessionStorage.setItem(to.path, from.fullPath)
  }
  next()
}

export default router
