import api from '@/plugins/api'
import { migration as endpoint } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'
import { PAGE_SIZE_MIGRATION } from '@/configs/constants'

const store = 'migration'

export default {
  state: () => ({
    migration: { results: [] },
    infoMigration: {},
    isLoadingMigrations: false
  }),
  actions: {
    async getMigrations (context, params = {}) {
      context.commit('SET_DATA', { store, state: 'isLoadingMigrations', data: true })

      params.page_size = PAGE_SIZE_MIGRATION
      let getParams = params && Object.keys(params)
        .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)

      const response = await api.get(`${ endpoint.portMigration }${ getParams ? `?${ getParams }` : ''}`)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'migration', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoadingMigrations', data: false })
    },

    async setMigrationInfo (context, body) {
      const response = await api.post(endpoint.portMigration, { body })
      return await requestValidation(response) ? response.data : {}
    },

    async updateMigrationInfo (context, data) {
      const response = await api.patch(endpoint.portMigration + `${ data.id }/`, { body: data.body })
      return await requestValidation(response) ? response.data : {}
    },

    async getMigrationInfo (context, id) {
      context.commit('SET_DATA', { store, state: 'isLoadingMigrations', data: true })
      
      const response = await api.get(endpoint.portMigration + `${ id }/`)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'infoMigration', data: response.data })
      }

      context.commit('SET_DATA', { store, state: 'isLoadingMigrations', data: false })
      return await requestValidation(response) ? response.data : {}
    },

    async getMigrationHistory (context, id) {
      context.commit('SET_DATA', { store, state: 'isLoadingMigrations', data: true })

      const response = await api.get(endpoint.portMigrationHistory(id))
      context.commit('SET_DATA', { store, state: 'isLoadingMigrations', data: false })

      return await requestValidation(response) ? response.data : {}
    }
  }
}
