import api from '@/plugins/api.js'
import { directories } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'

const store = 'directory'

export default {
  state: () => ({
    typeOlt: [],
    typeOnu: [],
    realTypeOnu: [],
    contentType: [],
    timePeriod: [
      { value: 60, name: 'Hour' },
      { value: 1440, name: 'Day' },
      { value: 10080, name: 'Week' },
      { value: 20160, name: 'Two weeks' },
      { value: 43800, name: 'Month' },
      { value: 87600, name: 'Two months' }
    ],
    statusesOnu: [
      { id: 1, name: 'Loss' },
      { id: 2, name: 'Does not exist' },
      { id: 3, name: 'Synchronize' },
      { id: 4, name: 'Online' },
      { id: 5, name: 'Power off' },
      { id: 6, name: 'Deleted' },
      { id: 7, name: 'Offline' },
      { id: 8, name: 'Unregistered' }
    ]
  }),
  actions: {
    getDirectories (context) {
      for (const directory of directories) {
        api.get(directory.endpoint).then(response => {
          if (requestValidation(response)) {
            context.commit('SET_DATA', { store, state: directory.state, data: response.data })
          }
        })
      }
    }
  },
  getters: {
    directoryById: state => (directory, id) => state[directory].find(item => Number(item.id) === Number(id)),
    directoryByModel: state => (directory, model) => state[directory].find(item => item.model === model),
    onuTypeByOlt: state => id => state.typeOnu.filter(item => Number(item.olt) === Number(id))
  }
}
