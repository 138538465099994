import api from '@/plugins/api'
import { buildings as endpoint } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'
import { PAGE_SIZE_BUILDING } from '@/configs/constants'

export default {
  state: () => ({
    buildings: []
  }),
  actions: {
    async getBuilding (context, id) {
      const response = await api.get(endpoint.building(id))
      await requestValidation(response)
      return response.data
    },
    async getMapBuilding (context, id) {
      const response = await api.get(endpoint.map(id))
      await requestValidation(response)
      return response.data
    },
    async getBuildings (context, params) {
      if (params.id) {
        const build = await context.dispatch('getBuilding', params.id)
        return { results: [build] }
      }
      params.page_size = PAGE_SIZE_BUILDING
      const getParams = params && Object.keys(params)
        .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)
      const response = await api.get(`${ endpoint.building() }${ getParams ? `?${ getParams }` : '' }`)
      await requestValidation(response)
      return response.data
    },
    async getMapBuildings (context, params) {
      if (params.id) {
        const build = await context.dispatch('getMapBuilding', params.id)
        return { results: [build] }
      }

      const getParams = params && Object.keys(params)
        .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)
      const response = await api.get(`${ endpoint.map() }${ getParams ? `?${ getParams }` : '' }`)
      await requestValidation(response)
      return response.data
    },
    async createBuilding (context, { body }) {
      const response = await api.post(endpoint.building(), { body })
      await requestValidation(response)
      return response.data
    },
    async editBuilding (context, data) {
      const response = await api.patch(endpoint.building(data.id), { body: data.body })
      await requestValidation(response)
      return response.data
    },
    async deleteBuilding (context, id) {
      const response = await api.delete(endpoint.building(id))
      return await requestValidation(response)
    },
    async getOnuInArea (context, polygon) {
      const url = `${ endpoint.refreshStatus }?polygon=${ polygon }`
      const response = await api.get(url)
      await requestValidation(response)
      return response.data
    },
    async refreshOnuInArea (context, polygon) {
      const response = await api.post(endpoint.refreshStatus, { body: { polygon } })
      await requestValidation(response)
      return response.data
    }
  }
}
