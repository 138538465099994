export default [
  {
    path: '/switch',
    name: 'switch',
    meta: {
      access: ['view_switch', 'add_switch', 'change_switch', 'delete_switch']
    },
    component: () => import('@/views/Switch/Switch')
  },
  {
    path: '/switch/:id',
    name: 'switch-info',
    meta: {
      access: ['view_switch', 'add_switch', 'change_switch', 'delete_switch'],
      prevRoutes: ['switch']
    },
    component: () => import('@/views/Switch/Info/Info')
  }
]
