<template>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            clip-path="url(#clip0_2236_1721)"
        >
            <!-- eslint-disable vue/max-len -->
            <path
                d="M8.24998 3.53331C8.82359 3.39905 9.41087 3.33193 9.99998 3.33331C15.8333 3.33331 19.1666 9.99998 19.1666 9.99998C18.6608 10.9463 18.0575 11.8373 17.3666 12.6583M11.7666 11.7666C11.5378 12.0123 11.2618 12.2093 10.9551 12.3459C10.6484 12.4826 10.3174 12.556 9.98172 12.5619C9.64604 12.5679 9.31261 12.5061 9.00132 12.3804C8.69003 12.2547 8.40725 12.0675 8.16985 11.8301C7.93246 11.5927 7.74531 11.3099 7.61957 10.9986C7.49383 10.6873 7.43208 10.3539 7.43801 10.0182C7.44393 9.68256 7.5174 9.35152 7.65404 9.04485C7.79068 8.73819 7.98769 8.46219 8.23331 8.23331M0.833313 0.833313L19.1666 19.1666M14.95 14.95C13.5255 16.0358 11.7909 16.6374 9.99998 16.6666C4.16665 16.6666 0.833313 9.99998 0.833313 9.99998C1.86989 8.06823 3.30759 6.38049 5.04998 5.04998L14.95 14.95Z"
                stroke="#667085"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <!-- eslint-enable vue/max-len -->
        </g>
        <defs>
            <clipPath
                id="clip0_2236_1721"
            >
                <rect
                    width="20"
                    height="20"
                    fill="white"
                />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
  name: 'EyeOff'
}
</script>
