export default [
  {
    path: '/log',
    name: 'log',
    meta: {
      access: ['view_accountlog'],
      prevRoutes: ['allRoutes']
    },
    component: () => import('@/views/Log/Log')
  },
  {
    path: '/log/:id',
    name: 'log-info',
    meta: {
      access: ['view_accountlog'],
      prevRoutes: ['allRoutes']
    },
    component: () => import('@/views/Log/Info')
  }
]
