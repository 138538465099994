import en from '@/configs/translations/en'
import uk from '@/configs/translations/uk'
import ru from '@/configs/translations/ru'
import hr from '@/configs/translations/hr'

let locale = localStorage.getItem('locale')
if (!locale) {
  locale = 'en'
  localStorage.setItem('locale', locale)
}
export default {
  locale,
  fallbackLocale: 'en',
  availableLocales: [{
    code: 'en',
    flag: 'us',
    label: 'English',
    messages: en
  }, {
    code: 'uk',
    flag: 'ua',
    label: 'Ukrainian',
    messages: uk
  }, {
    code: 'ru',
    flag: 'ru',
    label: 'Russia',
    messages: ru
  }, {
    code: 'hr',
    flag: 'hr',
    label: 'Croatian',
    messages: hr
  }]
}
