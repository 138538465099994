import moment from 'moment-timezone'
import i18n from '@/plugins/vue-i18n'
import router from '@/router'

export const convertTime = (date, format = 'DD.MM.YYYY HH:mm') => {
  if (!date) return i18n.t('statuses.unknow')

  const item = date === 'Now' ? moment() : date
  return moment(item).format(format)
}

export const convertTimeExpire = (date, format = 'DD.MM.YYYY HH:mm') => {
  if (!date)
    return i18n.t('infinity')
  return convertTime(date, format)
}

export const downloadFile = (blob, name) => {
  const a = document.createElement('a')
  a.href = URL.createObjectURL(blob)
  a.download = name
  a.click()
  a.remove()
}

export const pushBackRoute = name => {
  const path = sessionStorage.getItem(router.currentRoute.path)
  path ? router.push(path) : name && router.push({ name })
}

export const logout = () => {
  localStorage.removeItem('TOKEN')
  localStorage.removeItem('isOutdatedRelease')
  document.cookie = 'TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  location = '/login'
}

export const getSortingParam = options => {
  if (options?.sortBy || options?.sortDesc) {
    const {
      sortBy: [by],
      sortDesc: [desc]
    } = options

    if (!by && !desc) return []
    return [`${ desc ? '-' : '' }${ by }`]
  }
}

export function objectsEqual (o1, o2) {
  const entries1 = Object.entries(o1).sort()
  const entries2 = Object.entries(o2).sort()
  if (entries1.length !== entries2.length) {
    return false
  }
  for (let i = 0; i < entries1.length; ++i) {
    if (entries1[i][0] !== entries2[i][0]) {
      return false
    }
    let target1 = entries1[i][1]
    let target2 = entries2[i][1]

    if (typeof target1 === 'object' && typeof target2 === 'object') {
      target1 = Array.isArray(target1) ? target1.sort() : JSON.stringify(target1)
      target2 = Array.isArray(target2) ? target2.sort() : JSON.stringify(target2)
    }

    if (target1?.toString() !== target2?.toString()) {
      return false
    }

  }
  return true
}

function fallbackCopyTextToClipboard (text) {
  const textArea = document.createElement('textarea')
  textArea.value = text

  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) {
  }
  document.body.removeChild(textArea)
}

export function copyTextToClipboard (text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text)
}

export function encodeRFC5987ValueChars (str) {
  return encodeURIComponent(str)
    .replace(
      /[!'()*]/g,
      c => `%${ c.charCodeAt(0).toString(16).toUpperCase() }`
    )
}

export function mapRecursiveStopper ({ activePolygon, activePage }, data, params) {
  const polygon = activePolygon ? encodeRFC5987ValueChars(activePolygon) : null
  const next = data.next.replaceAll('+', '%20')
  const isSameSearch = polygon ? next.includes(polygon) : !next.includes('polygon=SRID')
  const isSameActivePage = activePage === params.page
  return !isSameSearch || !isSameActivePage
}

export function getProgressClass (result) {
  const amount = Number(result)
  switch (true) {
    case amount < 31:
      return 'success--text'
    case amount >= 31 && amount < 61:
      return 'info--text'
    case amount >= 61 && amount < 81:
      return 'warning--text'
    case amount >= 81:
      return 'error--text'
  }
}
