export default [
  {
    path: '/migration',
    name: 'olt-migration',
    meta: {
      access: [
        'add_migrationonustatus', 'change_migrationonustatus',
        'delete_migrationonustatus', 'view_migrationonustatus'
      ],
      prevRoutes: ['olt', 'olt-info']
    },
    component: () => import('@/views/Migration/Migration')
  },
  {
    path: '/migration/:id',
    name: 'olt-migration-info',
    meta: {
      access: [
        'add_migrationonustatus', 'change_migrationonustatus',
        'delete_migrationonustatus', 'view_migrationonustatus'
      ],
      prevRoutes: ['olt-migration']
    },
    component: () => import('@/views/Migration/Info/Info')
  }
]
