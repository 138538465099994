import groupBy from 'lodash.groupby'
import flatten from 'lodash.flatten'

export const getGroupPermissions = (permissionsList = []) => {
  if (permissionsList.length === 0) return permissionsList
  return groupBy(permissionsList, 'model_name')
}

export const getAccordionCollectionPermissions = (permissionsList = {}) => {
  const permissionsSwitchersKeys = Object.keys(permissionsList)
  if (permissionsSwitchersKeys.length === 0) return []

  const newArray = []

  for (const permissionsSwitchersKeyName of permissionsSwitchersKeys) {
    const newItems = permissionsList[permissionsSwitchersKeyName].map(item => {
      return {
        id: item.id,
        isActive: false,
        name: item.name
      }
    })

    const newObject = {
      name: permissionsSwitchersKeyName,
      isActive: false,
      items: newItems
    }
    newArray.push(newObject)
  }

  return newArray
}

export const getAccordionEditedCollectionPermissions = (permissionsList = {}, personalPermissionsList = []) => {
  const permissionsSwitchersKeys = Object.keys(permissionsList)
  if (permissionsSwitchersKeys.length === 0) return []
  if (personalPermissionsList.length === 0) {
    return getAccordionCollectionPermissions(permissionsList)
  }

  const newArray = []

  for (const permissionsSwitchersKeyName of permissionsSwitchersKeys) {
    const newItems = permissionsList[permissionsSwitchersKeyName].map(item => {
      return {
        id: item.id,
        isActive: personalPermissionsList.includes(item.id),
        name: item.name
      }
    })

    const isActive = newItems.every(el => el.isActive)

    const newObject = {
      name: permissionsSwitchersKeyName,
      isActive,
      items: newItems
    }
    newArray.push(newObject)
  }

  return newArray
}

export const getSwitchersIDsArray = (switchersIDsMap = new Map()) => {
  let switchersIDsArray = [...switchersIDsMap.values()]
  if (switchersIDsArray.length) {
    switchersIDsArray = switchersIDsArray.filter(el => el.length)
    switchersIDsArray = flatten(switchersIDsArray)
    return switchersIDsArray
  }
  return []
}

export const getAccordionSwitchersPanel = (accordionSwitchersPanel = [], { accordionIndex, index }) => {
  const newAccordionSwitchersPanel = accordionSwitchersPanel

  if (newAccordionSwitchersPanel.includes(index - 1)) {
    const indexPosition = newAccordionSwitchersPanel.indexOf(accordionIndex)
    newAccordionSwitchersPanel.splice(indexPosition, 1)
  } else {
    newAccordionSwitchersPanel.push(accordionIndex)
  }

  return newAccordionSwitchersPanel
}
