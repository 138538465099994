import api from '@/plugins/api.js'
import requestValidation from '@/configs/requestValidation'
import { PAGE_SIZE_LOG } from '@/configs/constants'
import { profile as endpoint } from '@/configs/endpoints'

const store = 'log'

export default {
  state: () => ({
    log: [],
    isLoadingLog: true
  }),
  actions: {
    async getLogs (context, params) {
      context.commit('SET_DATA', { store, state: 'isLoadingLog', data: true })

      params.page_size = PAGE_SIZE_LOG
      const getParams = params && Object.keys(params)
        .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)

      const response = await api.get(`${ endpoint.log() }${ getParams ? `?${ getParams }` : '' } `)

      if (await requestValidation(response, params.page === 1)) {
        context.commit('SET_DATA', { store, state: 'log', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoadingLog', data: false })
      return response.code
    },
    async getLog (context, id) {
      const response = await api.get(endpoint.log(id))
      await requestValidation(response)
      return response.data
    }
  }
}
