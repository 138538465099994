export default [
  {
    path: '/onu',
    name: 'onu',
    meta: {
      access: ['view_onu', 'add_onu', 'change_onu', 'delete_onu']
    },
    component: () => import('@/views/Onu/Onu')
  },
  {
    path: '/onu/variety',
    name: 'onu-variety',
    meta: {
      access: ['view_onu', 'add_onu', 'change_onu', 'delete_onu'],
      prevRoutes: ['onu']
    },
    component: () => import('@/views/Onu/Variety/Variety')
  },
  {
    path: '/onu/:id',
    name: 'onu-info',
    meta: {
      access: ['view_onu', 'add_onu', 'change_onu', 'delete_onu'],
      prevRoutes: ['onu']
    },
    component: () => import('@/views/Onu/Info/Info')
  },
  {
    path: '/onu/:olt_id/:slot_number/:port_number/:onu_number',
    name: 'onu-info-position',
    meta: {
      access: ['view_onu', 'add_onu', 'change_onu', 'delete_onu'],
      prevRoutes: ['onu']
    },
    component: () => import('@/views/Onu/Info/Info')
  }
]
