export default [
  {
    path: '/report',
    name: 'report',
    meta: {
      access: ['view_onu', 'add_onu', 'change_onu', 'delete_onu'],
      prevRoutes: ['allRoutes']
    },
    component: () => import('@/views/Report/Report')
  }
]
