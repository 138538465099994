import api from '@/plugins/api'
import notify from '@/plugins/notify'
import { core as endpoints } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'

export default {
  state: () => ({
    isLoad: true,
    isMini: false,
    isMobile: false,
    version: null,
    location: {
      country: 'Ukraine',
      city: 'Zaporizhzhia',
      street: '',
      house: ''
    }
  }),
  actions: {
    async getLatestRelease (context) {
      const { data } = await api.get(endpoints.latestRelease)
      context.commit('SET_DATA', { store: 'app', state: 'version', data: data.ep_version })
      context.commit('SET_DATA', { store: 'app', state: 'latestRelease', data })

      if (data.ep_is_outdated) {
        const link = 'https://docs.easypon.in/easypon-information/releases'
        const onClosing = () => localStorage.setItem('isOutdatedRelease', data.ep_is_outdated)

        notify.success(
          'notify.updateVersion',
          { link },
          true,
          onClosing
        )
      }
    },
    async getAvatarSource (context, url) {
      const response = await api.get(url, { responseType: 'blob' })
      await requestValidation(response)
      return URL.createObjectURL(response.data)
    },
    async getGoogleClientId () {
      return await api.get(endpoints.googleClientId)
    }
  },
  getters: {}
}
