
const SET_DATA = (context, data) => {
  context[data.store][data.state] = data.data
}

const ADD_DATA = (context, data) => {
  context[data.store][data.state].push(data.data)
}

const UPDATE_DATA = (context, data) => {
  console.log(context[data.store][data.state])
  context[data.store][data.state] = context[data.store][data.state].map(i => {
    if (Number(i.id) === Number(data.data.id)) {
      return data.data
    }
    return i
  })
}

const DELETE_DATA = (context, data) => {
  context[data.store][data.state] = context[data.store][data.state].filter(i => i.pk_md5 !== data.data)
}

const SET_DATA_PORT = (context, data) => {
  context.onu.info.ethernet_ports.map(i => {
    if (i.id === data.data.id) i.admin_status = data.data.admin_status
  })
}

export default {
  SET_DATA, ADD_DATA, UPDATE_DATA, DELETE_DATA, SET_DATA_PORT
}
