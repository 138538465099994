import api from '@/plugins/api'
import { switches as endpoint } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'
import { PAGE_SIZE_SWITCH } from '@/configs/constants'

const store = 'switch'

export default {
  state: () => ({
    switches: {results: []},
    infoSwitch: {},
    isLoadingAllSwitches: false,
    isLoadingInfoSwitch: false
  }),
  mutations: {
    UPDATE_PORTS (context, data) {
      context.infoSwitch.ports.map(i => {
        if (i.id === data.data.id) i.admin_status = data.data.admin_status
      })
    },
    SET_PORTS (context, data) {
      console.log(data)
      context.infoSwitch.ports = data.data
    }
  },
  actions: {
    async getAllSwitches (context, params) {
      context.commit('SET_DATA', { store, state: 'isLoadingAllSwitches', data: true })

      params.page_size = PAGE_SIZE_SWITCH
      let getParams = params && Object.keys(params)
        .reduce((r, x) => (r + x + '=' + params[x] + '&'), '').slice(0, -1)

      const response = await api.get(`${ endpoint.switchInfo }${ getParams ? `?${ getParams }` : ''} `)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'switches', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoadingAllSwitches', data: false })
    },

    async getInfoSwitch (context, id) {
      context.commit('SET_DATA', { store, state: 'isLoadingInfoSwitch', data: true })

      const response = await api.get(endpoint.switchAction(id))
      const responsePort = await api.get(endpoint.switchPort(id))

      const isResult = await requestValidation(response)
      const isResultPort = await requestValidation(responsePort)

      response.data.ports = isResultPort ? responsePort.data : []

      if (isResult) {
        context.commit('SET_DATA', { store, state: 'infoSwitch', data: response.data })
      }

      context.commit('SET_DATA', { store, state: 'isLoadingInfoSwitch', data: false })
      return response.code
    },

    async synchronizeSwitch (context, id) {
      const response = await api.get(endpoint.synchronize(id))
      const isResult = await requestValidation(response)

      if (isResult) {
        context.commit('SET_PORTS', { store, data: response.data })
      }
      return isResult
    },
    async postSwitch (context, body) {
      const response = await api.post(endpoint.switchInfo, { body: body })
      const isResult = await requestValidation(response)
      if (isResult) return response.data
      return {}

    },
    async deleteSwitch (context, id) {
      const response = await api.delete(endpoint.switchAction(id))
      return await requestValidation(response)
    },

    async changePort (context, data) {
      const response = await api.patch(endpoint.switchPortAction(data.switch, data.id), { body: data.body })

      const isResult = await requestValidation(response)
      isResult && context.commit('UPDATE_PORTS', { store, data: response.data })
      return isResult
    }
  },
  getters: {
    switchById: state => id => state.switches.find(item => Number(item.id) === Number(id))
  }
}
