export default [
  {
    path: '/permissions/user',
    name: 'user',
    meta: {
      access: ['view_user', 'add_user', 'change_user', 'delete_user']
    },
    component: () => import('@/views/Permissions/User/User.vue')
  },
  {
    path: '/permissions/user/create',
    name: 'create-user',
    meta: {
      access: 'add_user'
    },
    component: () => import('@/views/Permissions/User/Form.vue')
  },
  {
    path: '/user/:id',
    name: 'user-info',
    meta: {
      access: ['view_user', 'add_user', 'change_user', 'delete_user'],
      prevRoutes: ['user']
    },
    component: () => import('@/views/Permissions/User/Info.vue')
  },
  {
    path: '/user/:id/edit',
    name: 'user-edit',
    meta: {
      access: ['add_user', 'change_user', 'delete_user'],
      prevRoutes: ['user', 'user-info']
    },
    component: () => import('@/views/Permissions/User/Form.vue')
  }
]
